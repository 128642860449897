// print styles
#search,
#desktop-nav,
nav,
#offcanvas-nav,
#btn-hamburger,
#mobile-nav,
.social-share-buttons,
.contextual-nav,
.footer__info {
    display: none;
}

.template__sticky-header {
    position: relative;
}

.headroom--unpinned {
    transform: translateY(0%);
}

main>.large-8 {
    width: 100%
}
main>.large-offset-2 {
    margin-left: 0;
}

#breadcrumbs, #offcanvas-nav, .footer__mega, #footer-social, .connect, .footer__standard, .footer-icons, .template__utilities {
  display:none;
}

header,
.template--home header,
.template--child header {
  display: block;
  position: relative;
  margin-bottom: 20px;
  padding: 12px;

  a[href]:after {
    content: none !important;
  }

  #site-title-wrapper {
    display: none;
  }
}

.template--home .template__photo,
.template--child .template__photo,
.template__photo {
  height: auto;
}

.template__photo__textbox {
  position: relative;
  top: 0;
  height: auto;
  min-height: auto;
}

.template__photo__text {
  position: relative;
  top: 0;
  left: 0;
  padding: 0;
  max-width: none;
  transform: none;

  h1 {
    display: block;
    color: #000!important;
  }

  p {
    display: none;
  }
}

.template--home,
.template--child {
  header {
    margin-bottom: 0;

    .template__header {
      min-height: 0;
    }

    .template__sticky-header,
    .template__logo.masthead-sticky,
    .template__logo.masthead-reg {
      display: none;
    }

    .print-header,
    .print-footer {
      display: block;
    }

    .template__photo {
      margin-bottom: 0;
    }
  }
}

#content {
  a.button {
    display: none;
  }

  a[href]:after {
    content: none !important;
  }

  .article,
  .digital-document {
    width: 100%;
    margin-left: 0;

    .print-author {
      display: inline-block;
    }
  }

  .article__author { 
    .print-author {
      display: inline-block;
    }
  }
}

.footer {
  .print-footer {
    display: block;
  }

  .print-disclaimer {
    border: 1px solid #c5c6ca;
    display: block;
    margin-bottom: 15px;
    padding: 15px;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

